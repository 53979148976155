import React from 'react';
import '../header.css';
import './services.css'

import Image1 from '../image/microservices.jpg';
import Image2 from '../image/enterprise_transformation.jpg';
import Image3 from '../image/digital_transformation.jpg';

function ApplicationDevelopment() {
    return (
        <div className="card-group">
            <div className="card position-removed">
                <img src={Image1} className="card-img-top image_size" alt="..." />
                    <div className="card-body">
                    <h5 className="card-title">Microservices Development</h5>
                    <p className="card-text">This is a software development approach which
                        is solely focused on creating complex enterprise grade applications,
                        which can reduce development time and at the same time helps you to build h
                        ighly resilient applications. We have the expertise required
                        here to develop any complex application in microservice architecture or
                        to migrate your existing monolithic application into this kind of
                        architecture. For enterprise applications, which must serve many
                        clients in a short span of time this is the best way to go.
                    </p>
                    </div>
            </div>
            <div className="card position-removed">
                <img src={Image2} className="card-img-top image_size" alt="..." />
                    <div className="card-body">
                    <h5 className="card-title">Enterprise Transformation</h5>
                    <p className="card-text">
                        any organisation who wants to be ahead in
                        delivering to its customers is bound to adopt
                        the changes that are so large and complex for them,
                        here the team can help you bring any fundamental
                        or complex changes to optimise the processes
                        in such a way that the changes help you to be ahead.</p>
                    </div>
            </div>
            <div className="card position-removed">
                <img src={Image3} className="card-img-top image_size" alt="..." />
                    <div className="card-body">
                    <h5 className="card-title">Digital Transformation</h5>
                    <p className="card-text">an organisation has several management
                        needs and those are continuously evolving, our experts can help
                        you to get all the standard operations streamlined through digital
                        transformation. We understand the need for innovation in the standard procedures
                        in such a way that you can enhance your productivity and serve your customers better.
                    </p>
                    </div>
            </div>
        </div>
    );
}

export default ApplicationDevelopment;