import React from 'react';

function Career() {
    return (
        <div className='text-green-600 justify-center m-auto flex font text-2xl'>
            Career
        </div>
    );
}

export default Career;