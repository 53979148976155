import React from 'react';

function About() {
    return (
        <div className='text-green-600 justify-center m-auto flex font text-2xl'>
            About
        </div>
    );
}

export default About;