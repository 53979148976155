import React from 'react';
import '../header.css';
import Image1 from '../image/QA.jpeg';
import Image2 from '../image/backgroundImg2.jpeg';

function QualityAssurance() {
    return (
        <div className="card-group">
            <div className="card position-removed mobile-items">
                <img src={Image1} className="card-img-top image_size mobile_image_size" alt="..." />
                <div className="card-body">
                    <h5 className="card-title">Test Automation</h5>
                    <p className="card-text">
                        We all need a product that is robust,
                        and testing plays a major role in ensuring that.
                        Automation of such an important component will not
                        only make the whole process faster and reliable but
                        also be cost effective. Think of this as an investment for a better future.
                    </p>
                </div>
            </div>
            <div className="card position-removed mobile-items">
                <img src={Image1} className="card-img-top image_size mobile_image_size" alt="..." />
                <div className="card-body">
                    <h5 className="card-title">End to End testing</h5>
                    <p className="card-text">
                        This will comprise of unit testing,
                        integration testing, regression testing,
                        system testing, stress testing and performance testing.
                    </p>
                </div>
            </div>            
        </div>
    );
}

export default QualityAssurance;