import React from 'react';
import '../header.css';
import SecrityServicesImg from '../image/security_services.jpg';
import IAMImg from '../image/IAM.png';
import './services.css'


function SecrityServices() {
    return (
        <div className="card-group">
            <div className="card position-removed">
                <img src={SecrityServicesImg} className="card-img-top image_size" alt="..." />
                    <div className="card-body">
                    <h5 className="card-title">Web Security Assessment</h5>
                    <p className="card-text">                        
                        Knowing where you stand is the first step towards
                        getting where you want to be. With our experts assessing
                        and testing your product you can remain confident on the existing
                        capabilities of your product and what needs to be improved.
                    </p>
                    </div>
            </div>
            <div className="card position-removed">
                <img src={IAMImg} className="card-img-top" alt="..." />
                <div className="card-body">
                    <h5 className="card-title">IAM</h5>
                    <p className="card-text">                        
                        Identity Access Management is as important as it gets
                        when it comes to access based security. We can provide
                        you with a custom solution that meets all your security needs and is lightweight.
                        But if you want to implement an existing solution, we have got your back.
                    </p>
                </div>
            </div>            
        </div>
    );
}

export default SecrityServices;