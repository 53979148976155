import React from 'react';
import '../header.css';
import './home.css'



function Home() {
    return (
        <div className='my-20 home_main_container'>
            <div className='items-left'>
                <div className='information_text text-3xl text-zinc-500 text-center  border-b-4 border-neutral-200 w-96 m-auto'>
                    INFORMATION SERVICES
                </div>
                <div className='home-text home-text1 justify-content mx-auto text-zinc-900 w-2/3 mt-10 text-xl  duration-300 text-animation'>
                    We are delivery focused technology experts,
                    who can reshape your business process in such a way that
                    you can extract more from your existing resources and perform
                    better in the markets than your competition.
                    We know the challenges to handle ever changing
                    technology with existing business in action. Our teams at
                    Modulobytees possesses skills which can be a game changer for
                    any business and harness the potential of your organisation.
                    Here the teams are work on their values with higher ethics, integrity
                    and professionalism, we are totally committed to your success.
                    We believe in corporate symbiosis and wants to see our clients
                    succeed the goals with whatever they want to. We are dedicated to
                    make you better in your technological advancement which eventually
                    benefits your clients and customers.
                </div>
            </div>
        </div>
    );
}

export default Home;