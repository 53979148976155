import React from 'react';
import '../header.css';
import Image1 from '../image/cloud.jpg';
import Image2 from '../image/cloudImg.jpeg';
import Image3 from '../image/cloud.jpg';
import Image4 from '../image/cloudImg.jpeg';


function ApplicationDevelopment() {
    return (
        <div className="card-group">
            <div className="card position-removed">
                <img src={Image1} className="card-img-top cloud-image" alt="..." />
                    <div className="card-body">
                    <h5 className="card-title">Cloud Implementation Partner</h5>
                    <p className="card-text">We are happy to be your partner
                        in the journey of implementing your ideas and products on cloud.
                        We are proud to inform you that we are adept in implementing projects in all
                        big cloud platforms like AWS, Azure, GC and Databricks.
                    </p>
                    </div>
            </div>
            <div className="card position-removed">
                <img src={Image2} className="card-img-top cloud-image" alt="..." />
                    <div className="card-body">
                    <h5 className="card-title">Cloud Migration</h5>
                    <p className="card-text">
                        Having your own physical infrastructure
                        can be costly and tedious to maintain.
                        That is why everyone is moving towards cloud-based solutions,
                        and we are happy to migrate your
                        existing product to the world of clouds.</p>
                </div>
            </div>
            <div className="card position-removed">
                <img src={Image3} className="card-img-top cloud-image cloud-image" alt="..." />
                    <div className="card-body">
                    <h5 className="card-title">Cloud Transformation</h5>
                    <p className="card-text">
                        Want to transform your
                        existing product to cloud compatible as is? Think no further.
                    </p>
                    </div>
            </div>
            <div className="card position-removed">
                <img src={Image4} className="card-img-top cloud-image" alt="..." />
                    <div className="card-body">
                    <h5 className="card-title">Cloud Management</h5>
                    <p className="card-text">
                        The Cloud environment is ever changing
                        and ever improving so leave the round
                        the clock job of cloud management and maintenance to us.
                    </p>
                    </div>
            </div>
        </div>
    );
}

export default ApplicationDevelopment;