import React, { useState } from 'react'
import './contactus.css'


const ContactUs = () => {
  const [formStatus, setFormStatus] = React.useState('Send')
  const [value, setValue] = useState('') 
  const [email, setEmail] = useState('') 
  const [text, setText] = useState('') 

  const onSubmit = (e) => {
    e.preventDefault()
    setFormStatus('Submitting...')
    setValue('')
    setEmail('')
    setText('')
    const { name, email, message } = e.target.elements
    let conFom = {
      name: name.value,
      email: email.value,
      message: message.value,
    }
    // console.log(conFom)
  }

  return (
    <div className="container mt-5 contact_us_main_div">
        <div className="row contactus-text">
			<p className='contactus-text1'>Contact us</p>
            <p className="mb-3 contactus-text2">We'd love to hear from you!</p>
	    </div>
     
      <form onSubmit={onSubmit}>
        <div className="mb-3">
          <label className="form-label" htmlFor="name">
            Name
          </label>
          <input value={value} onChange={(e) => setValue(e.target.value)} className="form-control" type="text" id="name" required />
        </div>
        <div className="mb-3">
          <label className="form-label" htmlFor="email">
            Email
          </label>
          <input value={email} onChange={(e) => setEmail(e.target.email)} className="form-control" type="email" id="email" required />
        </div>
        <div className="mb-3">
          <label className="form-label" htmlFor="message">
            Message
          </label>
          <textarea value={text} onChange={(e) => setText(e.target.text)} className="form-control" id="message" required />
        </div>
        <button className="btn submit_button" type="submit">
          {/* {formStatus} */}
          Submit
        </button>
      </form>
    </div>
  )
}
export default ContactUs