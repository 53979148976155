import React from 'react';
import '../header.css';
import Image1 from '../image/blockchain.png';

function ApplicationDevelopment() {
    return (
        <div className="card-group">
            <div className="card position-removed block-chain">
                <img src={Image1} className="card-img-top image_size1 blockchain_image" alt="..." />
                    <div className="card-body blockchain_text_div">
                    <h5 className="card-title">Blockchain Enterprise Development</h5>
                    <p className="card-text blockchain-text">
                        The dawn of Blockchain has
                        opened new and exciting opportunities
                        for everyone. Let us help you make good on
                        these opportunities with our Blockchain Enterprise
                        Development solutions so that you can get the most out of all
                        the security and transparency that comes with blockchain technology.
                    </p>
                    </div>
            </div>            
        </div>
    );
}

export default ApplicationDevelopment;