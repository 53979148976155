import React from 'react';
import '../header.css';
import Image1 from '../image/iot.png';

function InternetOfthings() {
    return (
        <div className="card-group">
            <div className="card position-removed block-chain">
                <img src={Image1} className="card-img-top image_size1 iot_img" alt="..." />
                <div className="card-body iot_text_div">
                    <h5 className="card-title">Internet Of Things</h5>
                    <p className="card-text ">
                        IOT can bring all Sci-fi changes
                        to the reality of life, it can have various
                        applications ranging from healthcare, agriculture,
                        supply chain, manufacturing etc. our team of IOT experts
                        can help you to innovate and renovate your business with
                        the full potential of the Internet and its things.
                    </p>
                </div>
            </div>            
        </div>
    );
}

export default InternetOfthings;