import React from 'react';
import './about.css'


function WhyUs() {
    return (
      <div className='text-grey-400  justify-center max-w-2xl items-center m-auto font text-2xl'>
        <p className='whoWeare whyus'>
          We have Digital transformation perspective with
          enterprise client driven processes.
          We offer the best-in-class expertise
          ranging from niche technologies to latest ones
        </p>
      </div>
    );
}

export default WhyUs;