import React from 'react';
import '../header.css';
import Image1 from '../image/web_app.jpg';
import Image2 from '../image/mobile-api-integration.png';

function MobileDevelopment() {
    return (
        <div className="card-group">
            <div className="card position-removed mobile-items">
                <img src={Image1} className="card-img-top mobile_image_size " alt="..." />
                    <div className="card-body">
                    <h5 className="card-title">Mobile Solutions</h5>
                    <p className="card-text">This is a software development approach which
                        is solely focused on creating complex enterprise grade applications,
                        which can reduce development time and at the same time helps you to build h
                        ighly resilient applications. We have the expertise required
                        here to develop any complex application in microservice architecture or
                        to migrate your existing monolithic application into this kind of
                        architecture. For enterprise applications, which must serve many
                        clients in a short span of time this is the best way to go.
                    </p>
                    </div>
            </div>
            <div className="card position-removed mobile-items">
                <img src={Image2} className="card-img-top mobile_image_size" alt="..." />
                    <div className="card-body">
                    <h5 className="card-title">Mobile API Integrations</h5>
                    <p className="card-text">
                        any organisation who wants to be ahead in
                        delivering to its customers is bound to adopt
                        the changes that are so large and complex for them,
                        here the team can help you bring any fundamental
                        or complex changes to optimise the processes
                        in such a way that the changes help you to be ahead.</p>
                    </div>
            </div>            
        </div>
    );
}

export default MobileDevelopment;