import React from 'react';
import './footer.css'

function Footer() {
    return (
        <div className=''>
            <div className='flex-auto flex justify-around py-4 bg-slate-100 footer_detail'>
                <div className='w-1/3 our_value_text'>
                    <p className='text-2xl font-bold mb-2 text-green-600'>Our Values</p>
                    <span className=''>
                        Our value comes from adding value to our clients and we
                        are determined to do our work to the best of our abilities.
                        We believe in simplicity and quality because a software is
                        only worth its mettle if it is simple yet of high quality.
                        We offer a wide range of services because our team members bring their expertise
                        and experience from different fields of software development and working with companies
                        of various sizes and types.
                        We are always ready to customize our proposals to meet the clients needs.
                        A heart to heart conversation is all it takes to get on the same page and
                        reach a win-win solution, so let's have one.
                    </span>
                </div>
                <div className='footer_address'>
                    <p className='font-bold text-2xl mb-2 text-green-600'>Get In Touch</p>
                    <div className='flex items-center'>
                        <i className="fa-solid fa-location-dot text-green-600"></i>
                        <div className='flex'>
                            <a className='ml-2'>Jaipur, India, 302020</a>
                        </div>
                    </div>
                    <div className='flex items-center'>
                        <i className="fa-solid fa-envelope text-green-600"></i>
                        <div className='flex'>
                            <a className='ml-2'>contact@Modulobytes.com</a>
                        </div>
                    </div>
                    <div className='flex mt-8'>
                        <i className="fa-brands fa-linkedin mr-2 cursor-pointer rounded-full border-2 w-8 h-8 flex my-auto justify-center items-center hover:bg-green-600 hover:text-white"></i>
                        <i className="fa-brands fa-instagram mr-2 cursor-pointer rounded-full border-2 w-8 h-8 flex my-auto justify-center items-center hover:bg-green-600 hover:text-white"></i>
                        <i className="fa-brands fa-facebook mr-2 cursor-pointer rounded-full border-2 w-8 h-8 flex my-auto justify-center items-center hover:bg-green-600 hover:text-white"></i>
                    </div>
                </div>
                
            </div>
            <div className='bg-slate-800 '>
                <p className='copyright_text text-white py-4 ml-16'>Copyright © 2022 . All Rights Reserved for Modulobytees</p>
            </div>
        </div>
    );
}

export default Footer;